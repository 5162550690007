.content-cta {
    /* width: 100%;
    height: 75vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-size: cover;
    position: relative; */
    width: 100%;
    height: 75vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.shadow-img {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70vh;
    background: rgba(66, 73, 198, 0.4);
    z-index: 1;
}