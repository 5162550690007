@media (max-width: 2560px) {
    .icon-yellow {
        width: 40%;
    }
}

@media (max-width: 1280px) {
    .icon-yellow {
        width: 45%;
    }
}

@media (max-width: 1144px) {
    .icon-yellow {
        width: 50%;
    }
}

@media (max-width: 1030px) {
    .icon-yellow {
        width: 60%;
    }
}

@media (max-width: 880px) {
    .icon-yellow {
        width: 70%;
    }
}

@media (max-width: 650px) {
    .icon-yellow {
        width: 80%;
    }
}

@media (max-width: 580px) {
    .icon-yellow {
        width: 90%;
    }
}