@font-face {
  font-family: "Shne-Breit-Extraleicht";
  src: local("Shne-Breit-Extraleicht"),url("./assets/fonts/Shne-Breit-Extraleicht.ttf") format("truetype");
}

@font-face {
  font-family: "Shne-Breit-Regular";
  src: local("Shne-Breit-Regular"),url("./assets/fonts/Shne-Breit-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Shne-Breit-Halbfett";
  src: local("Shne-Breit-Halbfett"),url("./assets/fonts/Shne-Breit-Halbfett.ttf") format("truetype");
}

@font-face {
  font-family: "Shne-Breit-Bold";
  src: local("Shne-Breit-Bold"),url("./assets/fonts/Shne-Breit-Bold.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: 'Shne-Breit-Halbfett';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Shne-Breit-Halbfett';
}
